import React from 'react';
import { Link } from 'gatsby';
import Container from './Container';
import Skype from './Skype'
import Logo from './Logo'

const Header = () => {
  return (
     <header id="masthead">
      <Container>
      <div id="header">  
          <div id="logo">
            
              <h1> <Link to="/"> <Logo/> THEMEDOST</Link></h1>
          </div>
          <div id="menu">
                  <ul>
                      <li> <Link to="/">Home</Link> </li>
                      <li> <Link to="/about">About</Link> </li>
                      <li> <Link to="/contact">Contact</Link> </li>
                      <li> <Link to="/faq">FAQ</Link> </li>
                      <li>  
                          <Skype/>   
                          <a href="skype:jinsonadesigns?chat">Skype </a></li>
                  </ul>
          </div>
          
      </div>
      </Container>
  </header>
 
  );
};

export default Header;

