import React, { useState } from 'react'
import { Link } from 'gatsby';
import Skype from './Skype'

const MobileMenu = (props) => {
    const [setActive, setActiveState] = useState("");

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "")
    }
    return (

        <div id="mobile_menu" className={`${setActive}`}>
            <div className="menu-toggle" onClick={toggleAccordion}>
                <span className="ham ham1"></span>
                <span className="ham ham2 haml"></span>
                <span className="ham ham2 hamr"></span>
                <span className="ham ham3"></span>
            </div>
            <div className="menu-wrapper">

                <ul className="mob-menu">
                    <li> <Link to="/">Home</Link> </li>
                    <li> <Link to="/about">About</Link> </li>
                    <li> <Link to="/contact">Contact</Link> </li>
                    <li> <Link to="/faq">FAQ</Link> </li>
                    <li style={{display:"flex", alignItems:"center"}}> 
                        <Skype/>  
                        <a href="skype:jinsonadesigns?chat">Skype Connect </a>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default MobileMenu
