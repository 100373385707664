import React from 'react'
import Container from './Container'
import PropTypes from 'prop-types';

const Hero = ({title,description}) => {
    return (
        <div id="hero">
            <Container>
            <div className="hero-content">
                <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
                <p dangerouslySetInnerHTML={{__html: description}}></p>
            </div>
            </Container>
        </div>
    );
};
Hero.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
  };
export default Hero
