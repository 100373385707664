import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import 'assets/style/style.scss';

import Header from 'components/Header';
import Footer from 'components/Footer';
import MobileMenu from 'components/MobileMenu'

const Layout = (props) => {
  const { children, pageName} = props
  let className = '';

  if ( pageName ) {
    className = `${className} page-${pageName}`;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: className}}>
        <meta name="description" content="Responsive templates for osTicket"></meta>
        <meta name="keywords" content="osticket, osticket-themes, osticket-templates, responsive-osticket, themedost" />
        <html lang="en" />
      </Helmet>
      <MobileMenu></MobileMenu>
      <div className="wrapper">
        <Header/>
        <main>{ children }</main>
        <Footer />
      </div>
    </>
  );

};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string
}

export default Layout;