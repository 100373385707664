import React from "react"
import { Link } from 'gatsby';
import Container from './Container';

const ContactMe = () => {
    return (
        <div id="contact-me">
            <Container>
            <div id="contact-box">
                <h3> 
                Want to customize your osTicket site front-end <br/> to match the style of your business website?
                </h3>
                <button><Link to="/contact">Contact Me</Link></button>
            </div>
            </Container>
        </div>
    )
}

export default ContactMe