import React from 'react';
import { Link } from 'gatsby';
import Container from './Container';

const Footer = () => {
  return (

    <footer id="footer">
      <Container> 
        <div className="footer-area">
            <div className="footer-menu">
                <ul>
                      <li> <Link to="/">Home</Link> </li>
                      <li> <Link to="/faq">FAQ</Link> </li>
                      <li> <Link to="/privacy-policy">PRIVACY POLICY</Link> </li>
                      <li> <Link to="/support-policy">SUPPORT POLICY</Link> </li>
                </ul>
            </div>
            <div className="footer-credits">
                    <p> &copy; {new Date().getFullYear()} Themedost.com.  </p>
                <p className="credit">
                        The term osTicket is registered® trademark of osTicket.com.
                        Themedost.com does not imply any affiliation with or endorsement by them. 
                </p>     
                <p className="subcredit">
                    This website is built with the goodness of <a target="_blank" rel="noopener noreferrer" href="https://reactjs.org/">ReactJS</a>  with  <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/">GatsbyJS</a> and served by <a target="_blank" rel="noopener noreferrer" href="https://www.netlify.com/">Netlify</a> 
                  </p>       
            </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;